<template>
  <collapsable-card :title="$t('product.images')" flat>
    <v-row no-gutters>
      <v-col
        v-for="(image, i) in images"
        :key="i"
        class="d-flex child-flex ma-1"
        cols="4"
      >
        <v-card tile outlined>
          <v-btn
            @click="removeImage(i)"
            x-small
            fab
            depressed
            style="position: absolute; z-index:9; right: 0"
            class="ma-1"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
          <v-img :src="getImage(image)" aspect-ratio="1" class="grey lighten-2">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-2" />

    <div class="text-right mr-2">
      <v-btn depressed @click="selectImages">
        <v-icon>mdi-folder-multiple-image</v-icon>
        Select Image...
      </v-btn>
    </div>

    <v-divider class="mt-2" />

    <v-card-actions>
      <v-file-input
        v-model="image"
        @change="imageChanged"
        @click:clear="clearImage"
        accept="image/png, image/jpeg, image/bmp"
        placeholder="Pick an image"
        prepend-inner-icon="mdi-camera"
        :label="$t('general.imageFile')"
        show-size
        clearablef
        clear-icon="mdi-image-off"
        solo
        outlined
        flat
        dense
        :append-outer-icon="image ? 'mdi-progress-upload' : undefined"
        @click:append-outer="uploadImage"
        class="mt-3"
      ></v-file-input>
    </v-card-actions>
  </collapsable-card>
</template>

<script>
import CollapsableCard from "@/common/components/CollapsableCard";
import FilesManagerDialog from "@/common/dialogs/FilesManagerDialog";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProductUpoader",
  components: { CollapsableCard, FilesManagerDialog },
  props: {
    images: Array,
  },
  model: {
    prop: "images",
    event: "input",
  },
  data() {
    return {
      image: null,
      tempImage: null,
    };
  },
  mounted() {
    this.loadStore(this.getStoreIdParam);
  },
  computed: {
    ...mapGetters("stores", ["getStoreById"]),

    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getStore() {
      return this.getStoreById(this.getStoreIdParam);
    },
  },
  methods: {
    ...mapActions("ui", ["showDialog", "closeDialog", "showFeedback"]),
    ...mapActions("stores", ["loadStore"]),

    getImage(image) {
      return image && this.getStore ? `${this.getApiBaseUrl}/files/?path=images/stores/_${this.getStore.uuid}/${image}` : null;
    },
    removeImage(i) {
      const images = this.images.filter((f, fi) => fi !== i);
      this.$emit("input", images);
    },
    selectImages() {
      const dialog = {
        title: this.$t("general.selectImages"),
        component: FilesManagerDialog,
        params: {
          base: `images/stores/_${this.getStore.uuid}/products`,
          path: `images/stores/_${this.getStore.uuid}/products`,
          selection: [],
          filter: [".jpg", ".png", ".gif", ".svg"],
          body: this.$t("messages.selectImages"),
        },
        width: 650,
        actions: [
          {
            text: this.$t("general.okay"),
            click: ({ selection }) => {
              const images =
                this.notEmptyArray(this.images) &&
                this.notEmptyArray(selection)
                  ? [...this.images, ...selection.map((m) => "products/" + m)]
                  : [...selection.map((m) => "products/" + m)];
              this.$emit("input", images);
              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: this.$t("general.cancel"),
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };
      this.showDialog(dialog);
    },
    imageChanged(file) {
      console.log("New picture loaded");
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.tempImage = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        console.log("Old browser. No support for Filereader API");
      }
    },
    clearImage() {
      this.image = null;
      this.tempImage = null;
    },
    uploadImage() {
      if (this.image) {
        this.$upload(`/files/upload/?path=images/stores/_${this.getStore.uuid}/products/`, this.image)
          .then(({data:fileName}) => {
            const images = [...this.images, "products/" + fileName];
            this.$emit("input", images);
            this.showFeedback({
              text: "messages.updateDone",
              color: "success",
            });
          })
          .catch(() =>
            this.showFeedback({
              text: "messages.updateNotSuccessful",
              color: "grey",
            })
          );
      }
    },
  },
};
</script>
