<template>
  <full-view :title="getTitle" back-button>
    <template v-slot:toolbar>
      <v-btn :disabled="!valid" large depressed color="primary" @click="save">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto pa-0" :style="getViewHeightStyle(56)">
        <v-form ref="form" v-model="valid" class="mb-5">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <div class="pa-5">
                <v-text-field
                  v-model="item.name"
                  :counter="255"
                  :rules="rules.name"
                  :label="$t('general.name')"
                  required
                  dense
                />
                <v-textarea
                  v-model="item.description"
                  :counter="255"
                  :label="$t('general.description')"
                  rows="2"
                  dense
                />
                <v-select
                  v-model="item.unitTypeId"
                  :items="this.getTypesByGroup('UNIT_TYPE')"
                  :item-text="getLocale.lang === 'ar' ? 'nameAr' : 'name'"
                  item-value="id"
                  :rules="rules.unit"
                  :label="$t('product.unit')"
                  required
                  dense
                ></v-select>
                <v-text-field
                  v-model="item.unitPrice"
                  :rules="rules.price"
                  :label="$t('product.price')"
                  type="number"
                  required
                  dense
                ></v-text-field>
                <v-text-field
                  v-model="item.stock"
                  :rules="rules.stock"
                  :label="$t('product.stock')"
                  type="number"
                  required
                  dense
                ></v-text-field>
                <v-combobox
                  v-model="item.tags"
                  :items="getTags"
                  :label="$t('product.tags')"
                  multiple
                  append-icon="mdi-clipboard-list"
                >
                  <template
                    v-slot:selection="{ attrs, item, select, selected }"
                  >
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      small
                      class="pl-1 pr-1 ml-0 mr-1 mb-1"
                      label
                      @click="select"
                      @click:close="removeTag(item)"
                    >
                      <strong>{{ item }}</strong>
                    </v-chip>
                  </template>
                </v-combobox>
                <v-text-field
                  v-model="item.barcode"
                  :rules="rules.barcode"
                  :label="$t('product.barcode')"
                  required
                  dense
                ></v-text-field>
                <v-card flat outlined class="text-center">
                  <vue-barcode
                    height="30"
                    with="60"
                    v-bind:value="item.barcode"
                  />
                </v-card>
              </div>
            </v-col>
            <v-col cols="12" md="4" class="left-shadow">
              <div class="pa-5">
                <category-selector
                  :edit-mode="editMode"
                  v-model="item.category"
                  @input="updateCategoryId"
                />
              </div>
            </v-col>
            <v-col cols="12" md="4" class="left-shadow">
              <div class="pa-5">
                <product-images v-model="item.images" />
              </div>
            </v-col>
          </v-row>

          <form-bar
            :valid="valid"
            @save="save"
            @reset="reset"
            @validate="$refs.form.validate()"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import ProductImages from "./ProductImages";
import CategorySelector from "./CategorySelector";
import VueBarcode from "vue-barcode";
import FormBar from "@/common/components/FormBar";

export default {
  name: "ProductForm",
  components: {
    FullView,
    ProductImages,
    CategorySelector,
    VueBarcode,
    FormBar,
  },
  data() {
    return {
      item: {
        images:[]
      },
      valid: false,
      originalState: { id: 0 },
      rules: {
        name: [
          (v) => !!v || "Product name is required",
          (v) => (!!v && v.length >= 5) || "Name must be at least 2 characters",
          (v) =>
            (!!v && v.length <= 256) ||
            "Must be less than or eaqual to 64 characters",
        ],
        unit: [(v) => !!v || "Unit type is required"],
        price: [
          (v) => !!v || "Unit-Price is required",
          (v) => v >= 0 || "Unit-Price must be grator than or equal to zero",
        ],
        stock: [
          (v) => !!v || "Stock is required",
          (v) => v >= 0 || "Stock must be grator than or equal to zero",
        ],
        section: [(v) => !!v || "Section is required"],
        category: [(v) => !!v || "Category is required"],
        barcode: [(v) => !!v || "Barcode is required"],
      },
      section: null,
    };
  },
  mounted() {
    this.loadTypes(true);
    if (this.editMode) {
      this.loadStoreProductByUuid(this.uuidParam).then((item) => {
        this.item = {
          ...item,
          unitType: item.unitType ? item.unitType.name : null,
        };
        Object.assign(this.originalState, this.item);
      });
    }
  },
  computed: {
    ...mapGetters("types", ["getTypesByGroup"]),
    ...mapGetters("products", ["getStoreProductByUuid"]),

    uuidParam() {
      return this.$route.params.productUuid;
    },
    editMode() {
      return !!this.uuidParam && this.uuidParam !== "new";
    },

    getTitle() {
      if (this.editMode) {
        return this.$t("product.edit");
      } else {
        return this.$t("product.new");
      }
    },
    getTags() {
      const tags = this.getTypesByGroup("TAG");
      return this.notEmptyArray(tags) ? tags.map((m) => m.name) : [];
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),
    ...mapActions("products", ["loadStoreProductByUuid", "saveStoreProduct"]),

    save() {
      this.saveStoreProduct({
        item: this.item,
        editMode: this.editMode,
      }).then(() => {
        this.$$router.back();
      });
    },

    updateCategoryId() {
      this.item.categoryId = this.item.category ? this.item.category.id : 0;
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
    getImage(image) {
      return `${this.getApiBaseUrl}/files/?path=images/${image}`;
    },
    removeTag(tag) {
      if (this.notEmptyArray(this.item.tags)) {
        const t = this.item.tags.indexOf(tag);
        this.item.tags = this.item.tags.filter((f, i) => i !== t);
      }
    },
  },
};
</script>
